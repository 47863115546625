import { lazy } from "react";

const loadModule = (link) => lazy(() => import(`../../modules/${link}`));
const loadLandingModule = (link) => lazy(() => import(`../../landing/${link}`));

export const authRoutes = [
  {
    path: "/login",
    exact: true,
    Component: loadModule("Auth/Login"),
  },
  {
    path: "/secque",
    exact: false,
    Component: loadModule("Auth/SecurityQuestion"),
  },
  {
    path: "/signup-nationality",
    exact: false,
    Component: loadModule("Auth/Nationality"),
  },
  {
    path: "/signup",
    exact: false,
    Component: loadModule("Auth/BusinessInformation"),
  },

  {
    path: "/signup-type",
    exact: true,
    Component: loadModule("Auth/FinancialServiceType"),
  },
  {
    path: "/signup-docs",
    exact: false,
    Component: loadModule("Auth/DocumentUpload"),
  },
  {
    path: "/signup-subdomain",
    exact: false,
    Component: loadModule("Auth/Subdomain"),
  },
  {
    path: "/signup-contact-info",
    exact: false,
    Component: loadModule("Auth/ContactInformation"),
  },
  {
    path: "/reset-password/:token",
    exact: false,
    Component: loadModule("Auth/ResetPassword"),
  },
  {
    path: "/forgot-credential",
    exact: false,
    Component: loadModule("Auth/ForgotCredential"),
  },
  {
    path: "/new-account",
    exact: false,
    Component: loadModule("Auth/NewAccount"),
  },
];

export const verificationRoutes = [
  {
    path: "/verify-device",
    exact: false,
    Component: loadModule("Auth/VerifyDevice"),
  },
  {
    path: "/verify-email/:id",
    exact: false,
    Component: loadModule("Auth/VerifyEmail"),
  },
  {
    path: "/email-check/:type/:category",
    exact: false,
    Component: loadModule("Auth/EmailCheck"),
  },
];

export const mainRoutes = [
  {
    path: ["/loans/transaction/list", "/terminal/:terminalId/transaction"],
    exact: true,
    Component: loadModule("Transactions/TransactionsListing"),
  },
  {
    path: ["/transactions/:id"],
    exact: true,
    Component: loadModule("Transactions/Transaction"),
  },
  {
    path: ["/transactions", "/transactions/:id"],
    exact: true,
    Component: loadModule("Transactions/Transactions"),
  },
  {
    path: "/business/customers/:id/transactions",
    exact: true,
    Component: loadModule("Transactions/CustomerTransaction"),
  },
  {
    path: "/business/customers/:id/wallet-transactions",
    exact: true,
    Component: loadModule("Transactions/CustomerWalletTransactions"),
  },
  {
    path: ["/business/customers/list"],
    exact: true,
    Component: loadModule("Business/CustomerList"),
  },
  {
    path: ["/business/kyc"],
    exact: true,
    Component: loadModule("Business/KYCList"),
  },
  {
    path: ["/business/upload"],
    exact: true,
    Component: loadModule("Business/MerchantUpload"),
  },
  {
    path: ["/business/kyc/:id"],
    exact: true,
    Component: loadModule("Business/BusinessKYC"),
  },
  {
    path: ["/business/customers/history"],
    exact: true,
    Component: loadModule("Business/Referrals"),
  },
  {
    path: ["/business/customers/:id/profile"],
    exact: true,
    Component: loadModule("Business/Customer"),
  },
  {
    path: ["/business/aggregators/:id/customers"],
    exact: true,
    Component: loadModule("Business/AggregatorCustomerList"),
  },
  {
    path: ["/business/aggregators/:id/settings"],
    exact: true,
    Component: loadModule("Business/SalesCommission"),
  },
  {
    path: [
      "/business/aggregators/:id",
      "/business/aggregators/:id/subAggregators",
    ],
    exact: true,
    Component: loadModule("Business/Aggregator"),
  },
  {
    path: "/business/aggregators",
    exact: true,
    Component: loadModule("Business/SalesListing"),
  },
  {
    path: "/business",
    exact: true,
    Component: loadModule("Business/Business"),
  },
  {
    path: "/terminal",
    exact: true,
    Component: loadModule("TerminalManagement/TerminalManagement"),
  },
  {
    path: "/terminal/:id",
    exact: true,
    Component: loadModule("TerminalManagement/TerminalInfo"),
  },
  {
    path: "/consumer",
    exact: true,
    Component: loadModule("Consumer/Consumer"),
  },
  {
    path: "/consumer/:id",
    exact: true,
    Component: loadModule("Consumer/ConsumerProfile"),
  },
  {
    path: "/loans/requests/:id",
    exact: true,
    Component: loadModule("Loans/CustomerLoanRequest"),
  },
  {
    path: "/loans/requests",
    exact: true,
    Component: loadModule("Loans/LoanRequestList"),
  },
  {
    path: "/loans/repayment",
    exact: true,
    Component: loadModule("Loans/RepaymentList"),
  },
  {
    path: "/loans/repayment/:id",
    exact: true,
    Component: loadModule("Loans/RepaymentBreakdown"),
  },
  {
    path: "/loans/requests/:id/wallet",
    exact: true,
    Component: loadModule("Loans/CustomerWalletInformation"),
  },
  {
    path: "/loans/requests/:id/bank",
    exact: true,
    Component: loadModule("Loans/CustomerInformation"),
  },
  {
    path: "/loans/requests/:id/credit",
    exact: true,
    Component: loadModule("Loans/CustomerInformation"),
  },
  {
    path: "/loans",
    exact: true,
    Component: loadModule("Loans/Loans"),
  },
  {
    path: "/loans/create",
    exact: true,
    Component: loadModule("Loans/CreateLoanProduct"),
  },

  {
    path: "/support/ticket/:id/:userId/:isCreatedByBanker",
    exact: true,
    Component: loadModule("Support/Ticket"),
  },
  {
    path: "/chat",
    exact: true,
    Component: loadModule("Support/Chat"),
  },
  {
    path: "/support/ticket",
    exact: true,
    Component: loadModule("Support/TicketTable"),
  },
  {
    path: "/support",
    exact: true,
    Component: loadModule("Support/Support"),
  },
  {
    path: "/support/issue/:category",
    exact: true,
    Component: loadModule("Support/TransactionList"),
  },
  {
    path: "/settings",
    exact: true,
    Component: loadModule("Settings/Settings"),
  },
  {
    path: "/settlement",
    exact: true,
    Component: loadModule("Settlement/Settlement"),
  },
  {
    path: "/settlement/wallet-history",
    exact: true,
    Component: loadModule("Settlement/SettlementWalletHistory"),
  },
  {
    path: "/disbursement",
    exact: true,
    Component: loadModule("Disbursements/Disbursements"),
  },
  {
    path: ["/disbursement/wallet-history", "/loans/wallet-history"],
    exact: true,
    Component: loadModule("Disbursements/WalletHistory"),
  },
  {
    path: "/revenue",
    exact: true,
    Component: loadModule("Revenue/Revenue"),
  },
  {
    path: "/revenue/wallet-history",
    exact: true,
    Component: loadModule("Revenue/RevenueWalletHistory"),
  },

  {
    path: "/emtl",
    exact: true,
    Component: loadModule("ElectronicMoney/ElectronicMoney"),
  },
  {
    path: "/emtl/wallet-history",
    exact: true,
    Component: loadModule("ElectronicMoney/ElectronicWalletHistory"),
  },
  {
    path: "/engagement",
    exact: true,
    Component: loadModule("CustomerEngagement/CustomerEngagement"),
  },

  {
    path: "/engagement/create",
    exact: true,
    Component: loadModule("CustomerEngagement/CreateNotification"),
  },
  {
    path: "/analytics",
    exact: true,
    Component: loadModule("Analytics/Analytics"),
  },

  {
    path: "/subscription/feature-plan",
    exact: true,
    Component: loadModule("Subscription/FeatureSubscription"),
  },
  {
    path: "/subscription",
    exact: true,
    Component: loadModule("Subscription/Subscription"),
  },
  {
    path: "/account-form",
    exact: true,
    Component: loadModule("AccountForm/AccountFormOverview"),
  },
  {
    path: "/account-form/create",
    exact: true,
    Component: loadModule("AccountForm/AccountForm"),
  },
  {
    path: "/account-form/:id/list",
    exact: true,
    Component: loadModule("AccountForm/FormResponse"),
  },
  {
    path: "/account-form/response/:id",
    exact: true,
    Component: loadModule("AccountForm/IndividualResponse"),
  },
  {
    path: "/cashpoint",
    exact: true,
    Component: loadModule("Cashpoint/Cashpoint"),
  },
  {
    path: "/cashpoint-list/all",
    exact: true,
    Component: loadModule("Cashpoint/TableList"),
  },
  {
    path: "/cashpoint/:customerId",
    exact: true,
    Component: loadModule("Cashpoint/CustomerCashpoint"),
  },
  {
    path: "/cashpoint/:phoneNumber/:id",
    exact: true,
    Component: loadModule("Cashpoint/CashpointDetail"),
  },
  {
    path: "/cashpoint/:id/transaction/list",
    exact: true,
    Component: loadModule("Transactions/CustomerTransaction"),
  },
  {
    path: "/cashpoint/:id/wallet-transaction/list",
    exact: true,
    Component: loadModule("Transactions/CustomerWalletTransactions"),
  },
  {
    path: "/cashback",
    exact: true,
    Component: loadModule("Cashback/Cashback"),
  },
  {
    path: "/cashback/:id",
    exact: true,
    Component: loadModule("Cashback/LoyaltyDetailsPage"),
  },
  {
    path: ["/home", "/dashboard"],
    exact: true,
    Component: loadModule("Dashboard/Dashboard"),
  },
];

export const landingRoutes = [
  {
    path: "/products/terminal",
    exact: true,
    Component: loadLandingModule("Terminal"),
  },
  {
    path: "/products/agent-banking",
    exact: true,
    Component: loadLandingModule("AgentBanking"),
  },
  {
    path: "/products/lending",
    exact: true,
    Component: loadLandingModule("Lending"),
  },
  {
    path: "/products/virtual-account",
    exact: true,
    Component: loadLandingModule("VirtualAccounts"),
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    Component: loadLandingModule("TermsAndConditions"),
  },
  {
    path: "/policy",
    exact: true,
    Component: loadLandingModule("Policy"),
  },
  {
    path: "/privacy-policy",
    exact: true,
    Component: loadLandingModule("PrivacyPolicy"),
  },
  {
    path: "/",
    exact: true,
    Component: loadLandingModule("Home"),
  },
];
