import React from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const BlackButton = ({
  className = "",
  icon,
  size = "medium",
  onClick,
  children,
  loading,
  disabled,
  ...props
}) => {
  return (
    <Button
      className={`${className} inter h-full justify-center items-center py-3 px-4 flex font-extrabold rounded-lg disabled:opacity-75 text-white border-0 bg-[#021230] active:bg-[#021230]/70 focus:bg-[#021230]/70 hover:bg-[#021230]/70`}
      onClick={onClick}
      loading={loading}
      style={{
        color: "#fff",
      }}
      icon={icon}
      size={size}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

export const PrimaryButton = ({
  className = "",
  icon,
  size = "medium",
  onClick,
  children,
  ...props
}) => {
  const user = useSelector((s) => s.auth.user);

  return (
    <Button
      className={`${className} btn-primary`}
      onClick={onClick}
      style={{
        color: "#fff",
        backgroundColor: user?.color?.primaryColor || "#26857D",
      }}
      icon={icon}
      size={size}
      {...props}
    >
      {children}
    </Button>
  );
};

export const TransparentButton = ({
  className = "",
  icon,
  size = "medium",
  onClick,
  children,
  loading,
  disabled,
  ...props
}) => {
  return (
    <Button
      className={`${className} border-[#D0D5DD] inter rounded-lg text-black border bg-[#FFFFFF] active:bg-[#FFFFFF] focus:bg-[#FFFFFF] hover:bg-[#FFFFFF]`}
      onClick={onClick}
      loading={loading}
      icon={icon}
      size={size}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

export const LightButton = ({
  className = "",
  icon,
  size = "medium",
  onClick,
  children,
  type,
  ...props
}) => {
  return (
    <Button
      className={`${className} !text-[#475467] h-full flex justify-center items-center font-extrabold py-3 px-4 gap-2 border-[#F4F7F9] rounded-xl bg-[#FFFFFF] active:bg-[#FFFFFF] focus:bg-[#FFFFFF] hover:bg-[#FFFFFF]`}
      onClick={onClick}
      icon={icon}
      size={size}
      htmlType={type}
      {...props}
    >
      {children}
    </Button>
  );
};

export const ThemeLink = ({ children, ...props }) => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Link
      className="btn-primary !text-white"
      style={{
        backgroundColor: user?.color?.primaryColor || "#26857D",
      }}
      {...props}
    >
      {children}
    </Link>
  );
};
