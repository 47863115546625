import React from "react";
import { useSelector } from "react-redux";
import {
  ClientTransactionType,
  TransactionTypeMappings,
} from "../../utils/constants";

const MapTransactionType = ({ type, className = "" }) => {
  const {
    isCommercialBankEnabled = false,
    isPtspCommercialBankEnabled = false,
  } = useSelector(({ auth }) => auth.user);
  const isEnabled = isCommercialBankEnabled || isPtspCommercialBankEnabled;

  return (
    <div className={className}>
      {isEnabled
        ? TransactionTypeMappings[type] || type
        : ClientTransactionType[type] || type}
    </div>
  );
};

export default MapTransactionType;
