const url = {
  base: process.env.REACT_APP_API,
  account: "account",
  analytics: "analytics",
  bankAccount: "bank-account",
  transaction: "transaction",
  wallet: "wallet",
  media: "media",
  serviceFee: "service-fee",
  commissionFee: "commission-fee",
  staff: "staff",
  ticket: "ticket",
  terminal: "terminal",
  loan: "loan",
  aggregator: "aggregator",
  notification: "notification",
  cashback: "cashback",
  payout: "payout",
  cashpoint: "cashpoint",
  subscription: "subscription",
  secque: "secque",
  loyalty: "loyalty",
};

export default url;
