import React, { useCallback, useEffect, useRef, useState } from "react";
import { Menu, Layout, Button, Spin } from "antd";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  BankingIcon,
  CashpointIcon,
  CustomerIcon,
  EngageIcon,
  Home,
  SettingIcon,
  SupportIcon,
  TransactIcon,
  WalletIcon,
  ManagementSvg,
  AnalyticsIcon,
  ReconcileSvg,
  SubscriptionIcon,
} from "../components/Icons/MenuIcon";
import { FaBars, FaTimes } from "react-icons/fa";
import url from "../../api/url";
import { checkIfSubMenuIsDisabled, TransactionType } from "../utils/helper";
import useWindowSize from "../utils/windowSize";
import premium from "../../assets/image/crown.png";
import { handleCollapse } from "../../api/businessApi";
import { usePremiumType } from "../utils/Hooks";
import { PremiumFeatureType, UserRole } from "../utils/constants";

const { Sider } = Layout;

const submenu = ["customer", "wallet", "banking", "ticket", "loyalty"];

const menu = [
  { path: ["home"], key: "home" },
  {
    path: ["business"],
    key: "business",
    sub_menu: submenu[0],
  },
  {
    path: ["consumer"],
    key: "consumer",
    sub_menu: submenu[0],
  },
  { path: ["transactions"], key: "transactions" },
  { path: ["disbursement"], key: "disbursement", sub_menu: submenu[1] },
  { path: ["settlement"], key: "settlement", sub_menu: submenu[1] },
  { path: ["cashpoint"], key: "cashpoint" },
  { path: ["cashpoint"], key: "cashpoint" },

  { path: ["revenue"], key: "revenue", sub_menu: submenu[1] },
  { path: ["emtl"], key: "emtl", sub_menu: submenu[1] },
  { path: ["loans"], key: "loans", sub_menu: submenu[2] },
  { path: ["cashback"], key: "cashback", sub_menu: submenu[4] },
  { path: ["account-form"], key: "account-form", sub_menu: submenu[2] },
  { path: ["support"], key: "support", sub_menu: submenu[3] },
  { path: ["chat"], key: "chat", sub_menu: submenu[3] },
  { path: ["settings"], key: "settings" },
  { path: ["terminal"], key: "terminal" },
  { path: ["analytics"], key: "analytics" },
  { path: ["engagement"], key: "engagement" },
];

const PremiumLabel = ({ title }) => {
  return (
    <div className="flex justify-between">
      {" "}
      <span>{title}</span> <img src={premium} alt="premium-service" />
    </div>
  );
};

const Sidebar = ({ open, user }) => {
  const menuRef = useRef();
  const [selectedKey, setKey] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const role = user?.role === UserRole.ADMIN;
  const logoImage = user?.profileImageId;
  const pages = user.pages || [];
  const themeColor = user?.color?.primaryColor || "#26857D";
  const lightColor = user?.color?.secondaryColor || "#ecfdf3";
  const history = useHistory();
  const { isWalletDisabled, isBusinessDisabled } =
    checkIfSubMenuIsDisabled(pages);
  const { width } = useWindowSize();
  const isMobile = width < 1200;
  const { isLoading, premium } = usePremiumType({ type: null });
  const ctaButton =
    Object.values(premium[PremiumFeatureType.TMS]).length > 1 ||
    Object.values(premium[PremiumFeatureType.ANALYTICS]).length > 1
      ? {
          actionText: "View Subscription",
          plainText:
            "Explore our premium features and make the most out of your subscription today!",
        }
      : {
          actionText: "Try now",
          plainText: "Unlock exclusive benefits with our premium subscription.",
        };

  const items = [
    {
      key: "home",
      icon: <Home className="icon-selected" />,
      label: "Dashboard",
      disabled: !pages?.includes("HOME"),
    },
    {
      key: "transactions",
      icon: <TransactIcon className="icon-selected" />,
      label: "Transaction",
      disabled: !pages?.includes("TRANSACTIONS"),
    },
    {
      key: "customer",
      icon: <CustomerIcon className="icon-selected" />,
      label: "Customer",
      children: [
        {
          label: "Business",
          key: "business",
          disabled: !pages?.includes("BUSINESS"),
        },
        {
          label: "Consumer",
          key: "consumer",
          disabled: !pages?.includes("CONSUMER"),
        },
      ],
      disabled: isBusinessDisabled,
    },
    {
      key: "wallet",
      icon: <WalletIcon className="icon-selected" />,
      label: "Wallet",
      children: [
        {
          label: "Disbursement",
          key: "disbursement",
          disabled: !pages?.includes(TransactionType.DISBURSEMENT),
        },
        {
          label: "Settlement",
          key: "settlement",
          disabled: !pages?.includes(TransactionType.SETTLEMENT),
        },
        {
          label: "Revenue",
          key: "revenue",
          disabled: !pages?.includes(TransactionType.REVENUE),
        },
        {
          label: "Electronic Money Transfer Levy",
          key: "emtl",
          disabled: !pages?.includes(TransactionType.EMTL),
        },
      ],
      disabled: isWalletDisabled,
    },

    {
      key: "banking",
      icon: <BankingIcon className="icon-selected" />,
      label: "Banking",
      children: [
        {
          label: "Loan",
          key: "loans",
          disabled: !pages?.includes(TransactionType.LOAN),
        },
        {
          label: "NUBAN",
          key: "account-form",
          disabled: !pages?.includes("ACCOUNT-FORM"),
        },
      ],
      disabled:
        !pages?.includes(TransactionType.LOAN) &&
        !pages?.includes("ACCOUNT-FORM"),
    },
    {
      key: "loyalty",
      icon: <BankingIcon className="icon-selected" />,
      label: "Loyalty",
      children: [
        {
          label: "Cashback",
          key: "cashback",
          disabled: !pages?.includes("CASHBACK"),
        },
      ],
      disabled: !role && !pages?.includes("CASHBACK"),
    },

    {
      key: "cashpoint",
      icon: <CashpointIcon className="icon-selected" />,
      label: "Cashpoint",
      disabled: !pages?.includes("CASHPOINT"),
    },
    {
      key: "engagement",
      icon: <EngageIcon className="icon-selected" />,
      label: "Engagement",
      disabled: !pages?.includes("ENGAGEMENT"),
    },
    {
      key: "ticket",
      icon: <SupportIcon className="icon-selected" />,
      label: "Support",
      children: [
        {
          label: "Ticket",
          key: "support",
          disabled: false,
        },
        {
          label: "Chat",
          key: "chat",
          disabled: false,
        },
      ],
      disabled: !pages?.includes("SUPPORT"),
    },
    {
      key: "analytics",
      icon: <AnalyticsIcon className="icon-selected" />,
      label: <PremiumLabel title="Analytics" />,
      disabled: !role,
    },
    {
      key: "terminal",
      icon: <ManagementSvg className="icon-selected" />,
      label: <PremiumLabel title="TMS" />,
      disabled: !role,
    },

    {
      key: "reconcililation",
      icon: <ReconcileSvg className="icon-selected" />,
      label: <PremiumLabel title="Reconcililation" />,
      disabled: true,
    },

    {
      key: "settings",
      icon: <SettingIcon className="icon-selected" />,
      label: "Settings",
    },
  ];

  const filterItemsByDisabled = (items) => {
    return items.reduce((filteredItems, item) => {
      if (!item.disabled) {
        if (item.children) {
          const filteredChildren = filterItemsByDisabled(item.children);
          if (filteredChildren.length > 0) {
            filteredItems.push({ ...item, children: filteredChildren });
          }
        } else {
          filteredItems.push(item);
        }
      }
      return filteredItems;
    }, []);
  };

  const filteredItems = filterItemsByDisabled(items);

  const setSelectedMenuByLocation = useCallback(
    (menu) => {
      const path = location.pathname;
      const filteredMenu = menu?.filter((item) =>
        item.path?.some((itemPath) => path.includes(itemPath))
      )[0];
      const keyValue = filteredMenu?.key;
      if (keyValue) {
        if (!open) {
          setOpenKeys([filteredMenu?.sub_menu] || [""]);
        }
        return setKey(keyValue);
      }
      setKey();
    },
    [location.pathname, open]
  );

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (submenu.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  function toggle() {
    handleCollapse({ className: "", open: !open });
  }

  const close = useCallback((e) => {
    if (menuRef.current.contains(e.target)) {
      return;
    }
    handleCollapse({
      className: "collapse-layout",
      open: true,
    });
  }, []);

  useEffect(() => {
    if (width <= 1024) {
      document.addEventListener("mousedown", close);
    }
    return () => {
      document.removeEventListener("mousedown", close);
    };
  }, [width, close]);

  useEffect(() => {
    setSelectedMenuByLocation(menu);
  }, [setSelectedMenuByLocation]);

  return (
    <Sider
      ref={menuRef}
      theme="light"
      className="sidebar pb-2"
      breakpoint="xl"
      onBreakpoint={(open) =>
        handleCollapse({
          className: "",
          open,
        })
      }
      onCollapse={(value) => {
        handleCollapse({
          className: value ? "collapse-layout" : "",
          open: value,
        });
      }}
      collapsed={open}
      collapsedWidth={isMobile ? 0 : 80}
      width={width < 1600 ? 240 : 352}
      collapsible
    >
      <Button
        className="fixed bg-black w-32 h-32 border-0 xl:hidden shadow-none flex items-center 
            justify-center bg-white "
        size="large"
        icon={!open ? <FaTimes /> : <FaBars />}
        onClick={toggle}
      />
      <div>
        <div className="logo w-full max-h-20 h-20 align-start flex p-5 py-1.5">
          {logoImage && (
            <img
              className="max-w-full max-h-full object-contain"
              src={`${url.base}/media/${logoImage}`}
              alt="logoImage"
            />
          )}
        </div>

        <Menu
          className="menu px-4 pb-4"
          theme="light"
          mode="inline"
          defaultSelectedKeys={["home"]}
          selectedKeys={selectedKey}
          openKeys={openKeys}
          onClick={(value) => {
            history.push(`/${value.key}`);
            if (isMobile) {
              handleCollapse({
                className: "",
                open: true,
              });
            }
          }}
          onOpenChange={onOpenChange}
          items={filteredItems}
          style={{
            "--themeColor": themeColor,
            "--lightColor": lightColor,
          }}
        />
      </div>
      {role && (
        <Spin spinning={isLoading}>
          <section className="px-5 pt-5 pb-14">
            <Link to="/subscription">
              {open ? (
                <div className="bg-[#7F7DD31A] px-2 py-3 rounded-lg flex justify-center items-center">
                  <SubscriptionIcon />
                </div>
              ) : (
                <div className="px-4 py-5 bg-indigo-400 bg-opacity-10 rounded-lg flex-col gap-4 inline-flex">
                  <div className="basis-0 text-slate-900 font-semibold leading-tight">
                    Subscription
                  </div>
                  <p className="text-slate-600 text-sm font-normal leading-tight">
                    {ctaButton.plainText}
                  </p>
                  <span className="text-indigo-400 text-sm font-semibold leading-tight">
                    {ctaButton.actionText}
                  </span>
                </div>
              )}
            </Link>
          </section>
        </Spin>
      )}
    </Sider>
  );
};

export default Sidebar;
