import { walletTypes } from "../types";

const initialState = {
  WALLET: false,
  TRANSFER: false,
  isOpen: false,
  walletType: "",
  source: null,
  destination: null,
  showTransaction: false,
};

const walletReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case walletTypes.OPEN_WALLET_OPTION:
      return {
        ...state,
        isOpen: true,
        source: payload.source,
      };
    case walletTypes.CLOSE_WALLET_OPTION:
      return {
        ...state,
        isOpen: false,
      };
    case walletTypes.WALLET_TYPE_MODAL:
      return {
        ...state,
        [payload.target]: payload.value,
        destination: payload.destination,
        isOpen: false,
      };
    case walletTypes.CLOSE_ALL_WALLET_MODAL:
      return {
        ...state,
        WALLET: false,
        TRANSFER: false,
        isOpen: false,
      };
    case walletTypes.OPEN_TRANSACTION_MODAL:
      return {
        ...state,
        showTransaction: true,
      };
    case walletTypes.CLOSE_TRANSACTION_MODAL:
      return {
        ...state,
        showTransaction: false,
      };

    default:
      return state;
  }
};

export default walletReducer;
