import { CloseOutlined } from "@ant-design/icons";
import dollar_img from "../../assets/image/dollar_logo.png";
import naira_img from "../../assets/image/naira_logo.png";

export const AccountTypeStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  NOT_APPLICABLE: "NOT_APPLICABLE",
};

export const ApprovalStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVE",
  DECLINED: "DECLINE",
};

export const AccountType = {
  INDIVIDUAL: "INDIVIDUAL",
  BUSINESS: "BUSINESS",
};

export const Department = {
  SUPPORT: "CUSTOMER_SUPPORT",
  BUSINESS: "BUSINESS",
  FINANCE: "FINANCE",
};

export const CurrencyType = {
  USD: "USD",
  NAIRA: "NAIRA",
};

export const PremiumFeatureType = {
  TMS: "TMS",
  ANALYTICS: "ANALYTICS",
};

export const LienBy = {
  BANKER: "Lien By Banker",
  INTERNAL: "Lien By Grupp",
};

export const Service = {
  services: "SERVICEFEE",
  commission: "COMMISSION",
};

export const SettlementWalletType = {
  SETTLEMENT: "settlement",
  USDSETTLEMENT: "usdsettlement",
  USDINTLSETTLEMENT: "usdIntlSettlement",
  NAIRAINTLSETTLEMENT: "nairaIntlSettlement",
};

export const WalletTitle = {
  EMTL: "Electronic Money Transfer Levy",
};

export const ClientTransactionType = {
  STAMP_DUTY: WalletTitle.EMTL,
  "Stamp Duty": WalletTitle.EMTL,
  "Wallet Top-Up": "Payment via Transfer",
  WALLET_TOP_UP: "Payment via Transfer",
};

export const TransactionLevel = {
  ONE: "ONE",
  TWO: "TWO",
  THREE: "THREE",
};

export const TransactionTypeMappings = {
  Withdrawal: "POS Purchase",
  "POS Withdrawal": "POS Purchase",
  "Wallet Top-Up": "Payment via Transfer",
  WALLET_TOP_UP: "Payment via Transfer",
  "NAIRA Intl Withdrawal": "Naira Intl. Purchase",
  "USD Intl Withdrawal": "USD Intl. Purchase",
  STAMP_DUTY: WalletTitle.EMTL,
  "Stamp Duty": WalletTitle.EMTL,
};

export const UserRole = {
  ADMIN: "ADMIN",
  TEAM_LEAD: "TEAM_LEAD",
  TEAM_MEMBER: "TEAM_MEMBER",
  AGGREGATOR: "AGGREGATOR",
};

export const UserType = {
  BUSINESS: "BUSINESS",
  CUSTOMER: "CUSTOMER",
  AGGREGATOR: "AGGREGATOR",
  BANKER: "BANKER",
  SALES_AGGREGATOR: "SALES_AGGREGATOR",
  AGENT: "AGENT",
  MERCHANT: "MERCHANT",
};

export const UserAccountStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  BLOCKED: "BLOCKED",
  DELETED: "DELETED",
};

export const TerminalFilterCategory = {
  TRANSACTION: "TRANSACTION",
  ACTIVITY: "ACTIVITY",
};

export const FormInputType = {
  TEXT: "TEXT",
  DROPDOWN: "DROP_DOWN",
  CHECKBOX: "CHECKBOX",
  DATE: "DATE",
};

export const MapUserTypes = {
  AGENT: "Agent",
  MERCHANT: "Merchant",
  SALES_AGGREGATOR: "Aggregator",
  BANKER: "System",
  CUSTOMER: "Customer",
};

export const userTypesOption = [
  { value: "AGENT", name: "Agent" },
  { value: "MERCHANT", name: "Merchant" },
  { value: "SALES_AGGREGATOR", name: "Aggregator" },
  { value: "BANKER", name: "Banker" },
];

export const commercialUserTypes = [
  { value: "MERCHANT", name: "Merchant" },
  { value: "BANKER", name: "Banker" },
];

export const UserDetailStatus = {
  ACTIVE: "ACTIVE",
  BLOCKED: "BLOCKED",
};

export const DateOption = {
  MONTH: "MONTH",
  DAY: "DAY",
};

export const TerminalType = {
  HORIZONPAY_K11: "HORIZONPAY_K11",
  HORIZONPAY_T50: "HORIZONPAY_T50",
  MOREFUN_H9: "MOREFUN_H9",
  NEWLAND_N910: "NEWLAND_N910",
  NEWPOS_7210: "NEWPOS_7210",
  NEWPOS_9220: "NEWPOS_9220",
  SUNYARD_I80: "SUNYARD_I80",
  TELPO_TPS900: "TELPO_TPS900",
  TOPWISE_MP35P: "TOPWISE_MP35P",
  TOPWISE_T1: "TOPWISE_T1",
  TIANYU_P30: "TIANYU_P30",
};

export const TerminalVendor = {
  ITEX: "ITEX",
  ETOP: "ETOP",
  GA: "GA",
  FLW: "FLW",
};

export const SecqueType = {
  TRANSACTION: "TRANSACTION",
  SETTLEMENT: "SETTLEMENT",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
};

export const LoyaltyType = {
  FLAT: "FLAT",
  PERCENTAGE: "PERCENTAGE",
};

export const ModalProps = {
  maskClosable: false,
  style: {
    padding: "0px 30px 30px",
  },
  closeIcon: <CloseOutlined className="text-black" />,
  destroyOnClose: true,
  centered: true,
  className: "new-modal",
};

export const accountCheck = {
  status: "",
  message: null,
  bankName: null,
};

export const PremiumFeatureRecord = {
  TMS: { subscriptionStatus: null },
  ANALYTICS: { subscriptionStatus: null },
};

export const RequiredField = [
  {
    type: FormInputType.TEXT,
    label: "First Name",
    paragraph: false,
    required: true,
  },
  {
    type: FormInputType.TEXT,
    label: "Last Name",
    paragraph: false,
    required: true,
  },
  {
    type: FormInputType.TEXT,
    label: "Phone Number",
    paragraph: false,
    required: true,
  },
  {
    type: FormInputType.DROPDOWN,
    label: "State",
    paragraph: false,
    required: true,
  },
  {
    type: FormInputType.TEXT,
    label: "L.G.A",
    paragraph: false,
    required: true,
  },
  {
    type: FormInputType.TEXT,
    label: "Address",
    paragraph: false,
    required: true,
  },
  {
    type: FormInputType.DATE,
    label: "Date of Birth",
    paragraph: false,
    required: true,
  },
  {
    type: FormInputType.TEXT,
    label: "BVN",
    paragraph: false,
    required: true,
  },
];

export const initialTransactionStats = {
  successful: {
    value: 0,
    count: 0,
  },
  pending: {
    value: 0,
    count: 0,
  },
  failed: {
    value: 0,
    count: 0,
  },
  refunded: {
    value: 0,
    count: 0,
  },
  total: {
    value: 0,
    count: 0,
  },
};

export const textAreaStyle = {
  height: 45,
  resize: "none",
  padding: "7px 11px",
};

export const initialLoader = {
  loading: false,
  isLoading: false,
};

export const initialStatusMessage = {
  title: "",
  text: "",
};

export const inputAlphabetRule = {
  pattern: /^[a-zA-Z\s-]+$/,
  message: "Only alphabets are allowed",
};

export const inputAddressRule = {
  pattern: /^[a-zA-Z0-9\s.,&-]+$/,
  message: "No special characters allowed",
};

export const notificationCategoryOption = [
  {
    label: "Service Downtime",
    value: "SERVICE_DOWNTIME",
  },
  {
    label: "Service Uptime",
    value: "SERVICE_UPTIME",
  },
  {
    label: "User Performance",
    value: "USER_PERFORMANCE",
  },
  {
    label: "Product Awareness",
    value: "PRODUCT_AWARENESS",
  },
  {
    label: "General Announcement",
    value: "GENERAL_ANNOUNCEMENT",
  },
  {
    label: "Service Downtime",
    value: " No Category",
  },
];

export const filterBusinessOptions = {
  search: [
    { label: "Phone no.", value: "phoneNumber" },
    {
      label: "Username",
      value: "username",
    },
  ],
  filters: [
    {
      label: "Activity Status",
      fieldName: "status",
      options: [
        { name: "Active", value: UserAccountStatus.ACTIVE },
        { name: "Inactive", value: UserAccountStatus.INACTIVE },
        { name: "Lien", value: UserAccountStatus.BLOCKED },
      ],
    },
    {
      label: "User Type",
      fieldName: "userType",
      options: [
        { name: "Agent", value: "AGENT" },
        { name: "Merchant", value: "MERCHANT" },
      ],
    },
    {
      label: "User Account Status",
      fieldName: "accountTypeStatus",
      options: [
        {
          name: "Pending",
          value: "PENDING",
        },
        {
          name: "Approved",
          value: "APPROVED",
        },
        {
          name: "Declined",
          value: "DECLINED",
        },
        {
          name: "Not applicable",
          value: "",
        },
      ],
    },
    {
      label: "Account Type",
      fieldName: "accountType",
      options: [
        {
          name: "Individual Account",
          value: AccountType.INDIVIDUAL,
          className: "w-full",
        },
        {
          name: "Business Account",
          value: AccountType.BUSINESS,
          className: "w-full",
        },
      ],
    },
  ],
};

export const filterAggregatorOptions = {
  search: [{ label: "Phone no.", value: "phoneNumber" }],
  filters: [
    {
      label: "Activity Status",
      fieldName: "status",
      options: [
        { name: "Active", value: UserAccountStatus.ACTIVE },
        { name: "Inactive", value: UserAccountStatus.INACTIVE },
        { name: "Lien", value: UserAccountStatus.BLOCKED },
      ],
    },
  ],
};

export const filterKycOptions = {
  filters: [
    {
      label: "User Type",
      fieldName: "userType",
      options: [
        { name: "Agent", value: UserType.AGENT },
        { name: "Merchant", value: UserType.MERCHANT },
        { name: "Consumer", value: UserType.CUSTOMER },
      ],
    },
    {
      label: "Kyc Status",
      fieldName: "kycStatus",
      options: [
        {
          name: "Pending",
          value: "PENDING",
        },
        {
          name: "Declined",
          value: "DECLINE",
        },
        {
          name: "All",
          value: "",
        },
      ],
    },
    {
      label: "Account Type",
      fieldName: "accountType",
      options: [
        {
          name: "Individual Account",
          value: AccountType.INDIVIDUAL,
          className: "w-full",
        },
        {
          name: "Business Account",
          value: AccountType.BUSINESS,
          className: "w-full",
        },
      ],
    },
  ],

  search: [
    {
      label: "Phone No",
      value: "phoneNumber",
    },
    {
      label: "Username",
      value: "username",
    },
  ],
};

export const cashpointOption = {
  filters: [],
  search: [
    {
      label: "Phone No",
      value: "phoneNumber",
    },
  ],
};

export const terminalFilterList = {
  filters: [
    {
      label: "Terminal Mapping",
      fieldName: "assigned",
      options: [
        { name: "Mapped", value: true },
        { name: "Unmapped", value: false },
      ],
    },
  ],
  search: [
    { label: "Terminal ID", value: "terminalId" },
    { label: "Serial No", value: "serialNumber" },
  ],
};

export const cashpointTerminalFilter = {
  filters: [
    {
      label: "Terminal Mapping",
      fieldName: "status",
      options: [
        { name: "Mapped", value: "MAPPED" },
        { name: "Unmapped", value: "UNMAPPED" },
      ],
    },
  ],
  search: [{ label: "Terminal ID", value: "terminalId" }],
};

export const filterOption = {
  type: [
    {
      value: "",
      name: "All",
    },
    {
      value: "credit",
      name: "Credit",
    },
    {
      value: "debit",
      name: "Debit",
    },
  ],
  search: [{ label: "Trans Ref.", value: "reference" }],
};

export const terminalStatusOption = [
  {
    value: "active",
    label: "Active",
    className: "rounded-md my-0.5",
  },
  {
    value: "inActive",
    label: "Inactive",
    className: "rounded-md my-0.5",
  },
  {
    value: "damaged",
    label: "Damaged",
    className: "rounded-md my-0.5",
  },
];

export const salesOptionList = {
  search: [
    { label: "Email", value: "email" },
    { label: "Username", value: "username" },
    { label: "Phone no.", value: "phoneNumber" },
  ],
  filters: [
    {
      fieldName: "status",
      label: "Aggregator Status",
      options: [
        { name: "Active", value: UserAccountStatus.ACTIVE },
        { name: "Lien", value: UserAccountStatus.BLOCKED },
      ],
    },
    {
      fieldName: "category",
      label: "Aggregator Type",
      options: [
        { name: "Aggregator", value: "AGGREGAT0R" },
        {
          name: "Super Aggregator",
          value: "SUPER_AGGREGATOR",
          className: "w-full",
        },
      ],
    },
  ],
};

export const identityTypes = {
  bvn: "BVN",
  nin: "NIN",
  utility: "UTILITY_IMAGE",
  placeOfBusiness: "PLACE_OF_BUSINESS_IMAGE",
  identification: "IDENTIFICATION_IMAGE",
};

export const aggregatorApiType = {
  aggregatorTransation: "aggregatorTransation",
  customerTransation: "customerTransation",
  aggregatorSubMetrics: "aggregatorSubMetrics",
  customerMetrics: "customerMetrics",
  aggregatorList: "aggregatorList",
  customerList: "customerList",
};

export const pricingOption = [
  {
    value: "",
    icon: naira_img,
    label: <div className="font-[800] text-[#475467]">Default Pricing</div>,
  },
  {
    value: CurrencyType.NAIRA,
    icon: naira_img,
    label: <div className="font-[800] text-[#475467]">Intl. Pricing (₦)</div>,
  },
  {
    value: CurrencyType.USD,
    icon: dollar_img,
    label: <div className="font-[800] text-[#475467]">Intl. Pricing ($)</div>,
  },
];

export const currencyOption = [
  /*  {
    value: null,
    icon: null,
    label: "All Transaction",
  },*/
  {
    value: null,
    icon: naira_img,
    label: "Naira (₦)",
  },
  {
    value: CurrencyType.USD,
    icon: dollar_img,
    label: "Dollar ($)",
  },
];

export const walletCurrencyOption = [
  {
    value: SettlementWalletType.SETTLEMENT,
    icon: naira_img,
    label: "Naira (₦)",
  },
  {
    value: SettlementWalletType.NAIRAINTLSETTLEMENT,
    icon: naira_img,
    label: "Intl. Wallet (₦)",
  },

  {
    value: SettlementWalletType.USDINTLSETTLEMENT,
    icon: dollar_img,
    label: "Intl. Wallet ($)",
  },
];

export function NotificationFilter(isEnabled) {
  const filterOptions = [
    {
      fieldName: "category",
      label: "Message Category",
      options: [
        { name: "Service Downtime", value: "SERVICE_DOWNTIME" },
        { name: "Service Uptime", value: "SERVICE_UPTIME" },
        { name: "User Performance", value: "USER_PERFORMANCE" },
        { name: "Product Awareness", value: "PRODUCT_AWARENESS" },
        { name: "General Announcement", value: "GENERAL_ANNOUNCEMENT" },
        { name: "No Category", value: "NO_CATEGORY" },
      ],
    },
    {
      fieldName: "method",
      label: "Engagement Type",
      options: [
        { name: "SMS", value: "SMS" },
        { name: "APP", value: "IN_APP" },
      ],
    },
    !isEnabled && {
      fieldName: "userCategory",
      label: "User Category",
      options: [
        { name: "All", value: "" },
        { name: "Agent", value: "AGENT" },
        { name: "Merchant", value: "MERCHANT" },
      ],
    },
  ];

  return { filters: filterOptions.filter((option) => Boolean(option)) };
}

/**@type {import("../components/Modal/FilterModal").FilterObject} */
export const accountOpeningFilter = {
  filters: [
    {
      fieldName: "status",
      label: "Status",
      options: [
        {
          name: "All",
          value: null,
        },
        {
          name: "Active",
          value: UserAccountStatus.ACTIVE,
        },
        {
          name: "Inactive",
          value: UserAccountStatus.BLOCKED,
        },
      ],
    },
  ],
};

export const responseFilterOption = (stateOptions = []) => {
  return {
    search: [
      {
        label: "Phone No.",
        value: "phoneNumber",
      },
    ],
    filters: [
      {
        fieldName: "status",
        label: "Response Status",
        options: [
          {
            name: "All",
            value: null,
          },
          { name: "Approved", value: "APPROVED" },
          { name: "Declined", value: "DECLINED" },
          { name: "Pending", value: "PENDING" },
        ],
      },
      {
        fieldName: "state",
        label: "State",
        options: stateOptions,
        type: "select",
        fieldProps: {
          placeholder: "Select State",
        },
      },
    ],
  };
};

export const LoanProductFilterOption = (
  durationList = [],
  loanFrequencyList = []
) => ({
  filters: [
    {
      fieldName: "durationInDays",
      label: "Loan Duration",
      options: durationList.map((item) => ({
        name: item.day,
        value: item.duration,
      })),
    },
    {
      fieldName: "frequency",
      label: "Loan Frequency",
      options: loanFrequencyList.map((item) => ({
        name: item.name,
        value: item.name,
      })),
    },
  ],
});

export const DEFAULT_PRIMARY_COLOR = "#021230";

export const teamFilterOption = {
  filters: [
    {
      fieldName: "department",
      label: "Department",
      options: [
        { name: "Support", value: Department.SUPPORT },
        { name: "Business", value: Department.BUSINESS },
        { name: "Finance", value: Department.FINANCE },
      ],
    },
    {
      fieldName: "role",
      label: "Role",
      options: [
        { name: "Team member", value: UserRole.TEAM_MEMBER },
        { name: "Team Lead", value: UserRole.TEAM_LEAD },
      ],
    },
    {
      fieldName: "status",
      label: "User status",
      options: [
        { name: "Active", value: UserAccountStatus.ACTIVE },
        { name: "Inactive", value: UserAccountStatus.INACTIVE },
      ],
    },
  ],
  search: [
    {
      label: "Email",
      value: "email",
    },
  ],
};

const defaultValue = {
  firstName: { title: "First Name" },
  lastName: { title: "Surname" },
  email: { title: "Email Address" },
  phoneNumber: { title: "Phone Number" },
  bvnVerified: { title: "BVN" },
  ninVerified: { title: "NIN" },
  gender: { title: "Gender" },
  dob: { type: "date", title: "Date Of Birth" },
};

export const businessCategories = [
  {
    label: "Profile Picture",
    values: {
      profileImageId: { type: "image", title: "Personal Image" },
    },
  },
  {
    label: "Consumer Information",
    values: defaultValue,
  },
  {
    label: "Personal Information",
    canEdit: true,
    values: {
      ...defaultValue,
      profileImageId: { type: "image", title: "Director's Image" },
    },
  },
  {
    label: "Business Information",
    values: {
      businessName: { title: "Business Name" },
      email: { title: "Business Email" },
      address: { title: "Address" },
      state: { title: "State" },
      lga: { title: "LGA" },
      businessLogo: { type: "image", title: "Business Logo" },
      placeOfBusinessImageId: {
        type: "identity",
        title: "Place Of Business",
        identityType: identityTypes.placeOfBusiness,
      },
    },
  },
  {
    label: "CAC Information",
    values: {
      businessRegNo: { title: "RC Number" },
      taxIdNumber: { title: "Tax Identification Number" },
      cacDocumentId: { type: "image", title: "CAC Certificate" },
    },
  },
  {
    label: "Other Document",
    values: {
      utilityImageId: {
        type: "identity",
        title: "Utility Bill",
        identityType: identityTypes.utility,
      },
      identificationImageId: {
        type: "identity",
        title: "Identification",
        identityType: identityTypes.identification,
      },
    },
  },
];

export const statusMessage = {
  success: "Success",
  error: "Error!",
};

export const uploadFormFields = [
  {
    name: "firstName",
    label: "First Name",
    isRequired: true,
    rules: inputAlphabetRule,
  },
  {
    name: "lastName",
    label: "Last Name",
    isRequired: true,
    rules: inputAlphabetRule,
  },
  {
    name: "gender",
    label: "Gender",
    isRequired: true,
    options: [
      {
        label: "MALE",
        value: "MALE",
      },
      {
        label: "FEMALE",
        value: "FEMALE",
      },
    ],
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    isRequired: true,
    type: "number",
  },
  { name: "serialNumber", label: "Serial Number", isRequired: true },
  { name: "terminalId", label: "Terminal ID", isRequired: true },
  { name: "businessName", label: "Business Name", isRequired: true },
  { name: "email", label: "Email", isRequired: true },
  {
    name: "address",
    label: "Address",
    isRequired: true,
    rules: inputAddressRule,
  },
  { name: "nin", label: "NIN", isRequired: false, type: "number" },
  { name: "bvn", label: "BVN", isRequired: false, type: "number" },
  {
    name: "accountBankCode",
    label: "Bank Code",
    isRequired: false,
    type: "number",
  },
  { name: "accountName", label: "Account Name", isRequired: false },
  {
    name: "accountNumber",
    label: "Account Number",
    isRequired: false,
    type: "number",
  },
];

export const secqueSteps = {
  3: [{ title: "Start" }, { title: "Almost there" }, { title: "Final Stage" }],
  1: [{ title: "Start" }, { title: "Final Stage" }],
};

export const cashback_menu = ["loyalty", "transactions"];
export const cashback_metricsType = {
  scheme: "scheme",
  state: "state",
};

export const cashbackFilterOptions = {
  search: [
    {
      label: "Loyalty Name",
      value: "name",
    },
  ],
  filters: [
    {
      label: "Status",
      fieldName: "status",
      options: [
        { name: "Active", value: "active" },
        { name: "InActive", value: "inactive" },
      ],
    },
    {
      label: "Card Scheme",
      fieldName: "cardScheme",
      options: [
        { name: "Mastercard", value: "mastercard" },
        { name: "Verve", value: "verve" },
        { name: "Visa", value: "visa" },
        { name: "AfriGo", value: "afrigo" },
      ],
    },
    {
      label: "Loyalty Model",
      fieldName: "type",
      options: [
        { name: "Flat Fee", value: LoyaltyType.FLAT },
        { name: "Percentage", value: LoyaltyType.PERCENTAGE },
      ],
    },
  ],
};
export const cashbackTransactionsFilterOptions = {
  search: [
    {
      label: "Reference",
      value: "reference",
    },
    {
      label: "Original Transaction Reference",
      value: "originalReference",
    },
  ],
  filters: [
    {
      label: "Status",
      fieldName: "status",
      options: [
        { name: "Successful", value: "SUCCESSFUL" },
        { name: "Failed", value: "FAILED" },
        { name: "Pending", value: "PENDING" },
        { name: "Refunded", value: "REFUNDED" },
      ],
    },
  ],
};

export const cashbackMerchantsFilterOption = {
  search: [
    { label: "Phone no.", value: "phoneNumber" },
    {
      label: "Username",
      value: "username",
    },
  ],
  filters: [
    {
      label: "Terminal Status",
      fieldName: "terminalStatus",
      options: [
        { name: "Mapped", value: "active" },
        { name: "UnMapped", value: "inactive" },
      ],
    },
    {
      label: "User Status",
      fieldName: "status",
      options: [
        { name: "Active", value: "active" },
        { name: "InActive", value: "inactive" },
      ],
    },
    {
      label: "Upgrade Status",
      fieldName: "type",
      options: [
        { name: "Approved", value: "APPROVED" },
        { name: "Declined", value: "DECLINE" },
      ],
    },
  ],
};
